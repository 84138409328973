import React, { useEffect } from "react";

import Stories from "components/stories";

import { orange } from "theme/colors";

const FireTemplate = (props) => {
  //PROPS
  const {
    data: {
      page: { stories }
    },
    setColor
  } = props;
  //PROPS

  //USEEFFECTS
  useEffect(() => {
    setColor(false);

    return () => {
      setColor(true);
    };
  }, []);
  //USEEFFECTS

  return <Stories data={stories} color={orange.default} />;
};

export const query = graphql`
  query Fire($id: String) {
    page: wpPage(id: { eq: $id }) {
      stories {
        slidess {
          title
          tips {
            tip
          }
          text
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  src
                  srcSet
                }
              }
            }
          }
        }
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                src
                srcSet
              }
            }
          }
        }
        mobileImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                src
                srcSet
              }
            }
          }
        }
      }
    }
  }
`;
export default FireTemplate;
